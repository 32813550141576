// Import Fuse core library
@import "@fuse/scss/core";

// Import app.theme.scss
@import "app/app.theme";

navbar navbar-vertical-style-2 .navbar-header {
    height: 50px !important;
    min-height: 50px !important;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    height: 50px !important;
}

toolbar .user-button,
toolbar fuse-search-bar,
toolbar .language-button,
toolbar .chat-panel-toggle-button,
toolbar .quick-panel-toggle-button {
    min-width: 50px !important;
    height: 50px !important;
}

toolbar .toolbar-separator {
    height: 50px !important;
}

.fuse-navy-900 {
    background-color: #304ffe !important;
}

.fuse-navy-700 {
    //background-color: #F5FAFD
    background-color: #fff;
}

body.theme-default {
    color: #222 !important;
    //   font-family: Arial, Helvetica, sans-serif!important;
}

body.theme-default .mat-flat-button.mat-primary,
body.theme-default .mat-raised-button.mat-primary,
body.theme-default .mat-fab.mat-primary,
body.theme-default .mat-mini-fab.mat-primary {
    background-color: #304ffe !important;
    font-family: SF Pro Display !important;
}

body.theme-default .mat-cell,
body.theme-default .mat-footer-cell {
    color: #222 !important;
    font-family: SF Pro Display !important;
}

content .mat-button,
content .mat-raised-button,
content .mat-stroked-button,
content .mat-flat-button {
    margin: 8px 8px 8px 0 !important;
}

.mat-snack-bar-container .flex {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.snackbar-error {
    background-color: #d32f2f !important;
}

.snackbar-done {
    background-color: #087f23 !important;
}

.snackbar-info {
    background-color: #004c8c !important;
}

.snackbar-warning {
    background-color: #c68400 !important;
}

// 11 Close Bnt
.close-button {
    float: right;
    top: -15px;
    right: -15px;

    .close-icon {
        transition: 1s ease-in-out;
    }

    .close-icon:hover {
        transform: rotate(180deg);
    }
}

ul {
    list-style: none;
}

ul li::before {
    content: "\2022";
    color: #141ED2;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.custom-dialog-container .mat-dialog-container {
    /* add your styles */
    padding: 0px !important;
}

.border-1 {
    margin: 10px 0;
    border-bottom: 1px solid #cacaca;
}

.border-2 {
    border-bottom: 1px solid #cacaca;
}

//dp

.dp-flex {
    display: flex !important;
}

.dp-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.dp-flex-start-center {
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
}

.dp-flex-space-center {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}

.dp-flex-space {
    display: flex !important;
    justify-content: space-between !important;
}

.flex-collum {
    flex-direction: column;
}

.dp-flex-space-end {
    display: flex !important;
    justify-content: space-between !important;
    align-items: flex-end !important;
}

.dp-flex-wrap {
    display: flex !important;
    flex-wrap: wrap !important;
}

.dp-flex-wrap-center {
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}

.pr {
    position: relative !important;
}

.pa {
    position: absolute !important;
}

.w-s {
    max-width: 100%;
    overflow: auto;
}

.btn-default {
    // background: #1aa1e1 !important;
    // color: #fff !important;
}

.btn-primary {
    background: #1aa1e1 !important;
    color: #fff !important;
}

.btn-succes {
    background: #32ad01 !important;
    color: #fff !important;
}

.btn-disabled {
    background: #cacaca !important;
    color: #fff !important;
    pointer-events: none;
}

//text
.text-green {
    color: #087f23 !important;
}

.text-red {
    color: #1aa1e1 !important;
}

.text-yellow {
    color: #c68400 !important;
}

.text-blue {
    color: #141ED2 !important;
}

.bold-32 {
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    color: #222222;
    mix-blend-mode: normal;
    opacity: 0.9;
}

.bold-18 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #222222;
}

.bold-22 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0px;
    color: #222222;
}

.normmal-16-center {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #222222;
}

.normal-16-600 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
}

.normal-16 {

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #222222;
}

.bold-16 {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #222222;
}

.text-500 {
    font-weight: 500 !important;
}

.thin-gray-16 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    color: #7A7A7A;
}

.text-gray {
    color: #7A7A7A !important;
}

.normal-14 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #222222;
}

.text-center {
    text-align: center;
}

.hight-light-text {
    color: #1aa1e1;
    text-decoration: underline;
}

.text-underline {
    text-decoration: underline !important;
}

.text-break-line {
    // white-space: pre-line !important;
    word-break: break-word !important;
}

.bg-green {
    background-color: #087f23 !important;
}

.bg-red {
    background-color: #1aa1e1 !important;
}

.bg-yellow {
    background-color: #c68400 !important;
}

.bg-blue {
    background-color: #141ED2 !important;
}

.text-break-line {
    // white-space: pre-line !important;
    word-break: break-word !important;
}

.disable-text-copy {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

.m0 {
    margin: 0 !important;
}

.fw {
    width: 100% !important;
}

.w-50 {
    width: 50% !important;
}

.mt-5 {
    margin-top: 5px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mb-16 {
    margin-bottom: 16px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.mr-3 {
    margin-right: 3% !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.cp {
    cursor: pointer !important;
}

.cd {
    cursor: default !important;
}

* {
    -webkit-overflow-scrolling: touch;
    font-family: SF Pro Display !important;
}

.container {
    background-color: #fff;
}

.info-des {

    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0 !important;
        padding: 0 !important;
    }
}

//snackbar
.snackBar-info {
    background: #047ac4 !important;
    color: #FFF !important;
    width: 100% !important;

    button {
        color: #FFF !important;

        &:hover {
            background: #034b77 !important;
        }
    }
}

.snackBar-succes {
    background: #5db899 !important;
    color: #FFF !important;
    width: 100% !important;

    button {
        color: #FFF !important;

        &:hover {
            background: #1d8663 !important;
        }
    }
}

.snackBar-error {
    background: #D92027 !important;
    color: #FFF !important;
    width: 100% !important;

    button {
        color: #FFF !important;

        &:hover {
            background: #850b1b !important;
        }
    }
}

.snackBar-warn {
    background: #F2A51A !important;
    color: #FFF !important;
    width: 100% !important;

    button {
        color: #FFF !important;

        &:hover {
            background: #866d1f !important;
        }
    }
}

.custom-dialog-container-img {
    .mat-dialog-content {
        max-height: 100%
    }

    .mat-dialog-container {
        overflow: hidden !important;
    }

    .trend-dialog .mat-dialog-container {
        overflow-y: hidden !important;
    }
}

.err {
    //styleName: Font 14/Regular 14;
    position: absolute;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    // margin: -4px 0 0 20px;
    bottom: -17px;
    left: 0;
    color: #EE0033;
    // padding-left: 45px;
}

.inputGotErr {
    // border-color: #1aa1e1;
    color: #1aa1e1 !important;

    div {
        color: #1aa1e1 !important;

    }

    ::-webkit-input-placeholder {
        /* Edge */
        color: #F899AD !important;
        font-weight: 400 !important;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #F899AD !important;
    }

    ::placeholder {
        color: #F899AD !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
        background: #FFFFFF !important;
        border: 1px solid #1aa1e1 !important;
        border-radius: 12px !important;
    }
}

::ng-deep .mat-raised-button.mat-button-disabled {
    background: #cacaca44 !important;
}

.mat-expansion-panel {
    box-shadow: none !important;
    padding-bottom: 10px !important;
    // margin-top: 10px !important;
    border-bottom: 1px solid #cacaca !important;
}

.mat-expansion-panel-spacing {
    margin: 0px !important;
}

.mat-expansion-panel-header {
    height: auto !important;
    padding: 0px !important;
    margin-top: 10px;
}

.mat-expansion-panel-body {
    padding: 0 !important
}

.mat-tab-header {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 4px !important;
}

.mat-accordion .mat-expansion-panel,
.mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.input-no-space {
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        margin: 0px !important;
        padding: 0px !important;
    }
}

@keyframes blink {
    0% {
        color: #1aa1e1;
    }

    100% {
        color: black;
    }
}

@-webkit-keyframes blink {
    0% {
        color: #1aa1e1;
    }

    100% {
        color: black;
    }
}

.blink {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 1s linear infinite;
}
.text-break-line {
    // white-space: pre-line !important;
    word-break: break-word !important;
}
.custom-dialog-container .mat-dialog-container {
    /* add your styles */
    padding: 0px !important;
    border: none !important;
    border-radius: unset !important;
    background: rgba(0,0,0,0) !important;
    border-radius: 8px !important;
}
